import React from 'react';
import Link from 'gatsby-link';
import { graphql, useStaticQuery } from "gatsby"
import Layout from '../components/layout/layout';
import moment from 'moment'
import '../styles/blogPage.scss';

const BlogPage = ({ location }) => {

  const data = useStaticQuery(graphql`
    {
      allContentfulPost {
        edges {
          node {
            id
            name
            slug
            image {
              file {
                url
              }
            }
            content {
              raw
            }
          }
        }
      }
    }`)

  const renderPosts = () => {
    const { allContentfulPost } = data;

    if (allContentfulPost.edges) {
      return allContentfulPost.edges.map(({ node: post }) => (
        <Link to={`/blog/${post.slug}`} key={post.id} className="PostItem">
          <div
            style={{
              backgroundImage: post.image.file.url
                ? `url(${post.image.file.url})`
                : 'url(https://via.placeholder.com/350x150)',
            }}
            className="PostItem__image"
          />
          <div className="PostItem__content">
            <p className="PostItem__date">
              {moment(post.date).format('MMMM Do YYYY')}
            </p>
            <h3 className="PostItem__title">{post.name}</h3>
          </div>
        </Link>
      ));
    }

    return '';
  };

  return (
    <Layout location={location}>
      <div className="BlogPage">
        <h1>Blog</h1>
        <div className="BlogPage__content">{renderPosts()}</div>
      </div>
    </Layout>
  );
}

export default BlogPage